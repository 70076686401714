























import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import WelfareBlindBox from "@/views/welfare/components/WelfareBlindBox.vue";

@Component({
  components: {
    WelfareBlindBox,
  },
})
export default class MuchBlind extends Vue {}
