




























































import { Component, Prop, Vue } from "vue-property-decorator";
import DestinyFrame from "../DestinyFrame.vue";
import PowerItem from "./PowerItem.vue";
import {
  countdown,
  getCharacter,
  getCharacterColor,
  getTomorrowMorning,
  observer,
} from "@/utils/common.util";
import { Coupon } from "@/api/mine.api";

@Component({
  components: { PowerItem, DestinyFrame },
})
export default class PowerBuff extends Vue {
  @Prop({ default: () => null })
  private readonly coupon!: Coupon | null;

  @Prop({ default: () => ({ num: 0, character: 0 }) })
  private readonly ensure!: { count: number; num: number; character: number };

  @Prop({ default: () => ({ is_free: false, is_has: false }) })
  private readonly free!: { is_free: boolean; is_has: boolean };

  @Prop()
  private readonly boxId!: number;

  cover = "https://cdn.vgn.cn/static/blind-box/";
  countdown = "";
  free_countdown = "";

  get characterText() {
    const { character } = this.ensure;
    const text = getCharacter(character, "", true);
    const icon = character < 5 ? "" : `__character_peak_${character - 4}_icon`;
    return { text, icon };
  }

  get characterStyle() {
    const { character } = this.ensure;
    return getCharacterColor(character);
  }

  couponCountdown() {
    if (!this.coupon) return;
    const { day, hour, minute, second } = countdown(this.coupon.failure_time);
    const last = `${day}天`;
    this.countdown = `剩余${
      Number(day) ? last : ""
    }${hour}:${minute}:${second}`;
  }

  freeCountdown() {
    if (!this.free.is_free) return;
    const { day, hour, minute, second } = countdown(getTomorrowMorning());
    const last = `${day}天`;
    this.free_countdown = `下次免费${
      Number(day) ? last : ""
    }${hour}:${minute}:${second}`;
  }

  countdownNotice() {
    this.couponCountdown();
    this.freeCountdown();
  }

  async mounted() {
    observer("countdownNotice", () => this.countdownNotice());
  }
}
