














import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class PowerItem extends Vue {
  @Prop({ default: "" })
  private readonly tag!: string;

  @Prop({ default: "" })
  private readonly title!: string;

  @Prop({ default: "" })
  private readonly cover!: string;
}
