





















































import { getCharacter } from "@/utils/common.util";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class RateCard extends Vue {
  @Prop()
  item!: any;
  @Prop()
  index!: number;
  @Prop()
  chanceRes!: any[];
  
  get characterText() {
    const { character } = this.item;
    const text = getCharacter(character, "", true);
    return text;
  }
  get total() {
    return this.chanceRes.map((item: any) => item.original_chance).reduce((a,b) => a+b, 0);
  }
  created() {
  }
  @Emit()
  previewCard(id: number): number {
    return id;
  }
  get ClientWidthStyle() {
    const { clientWidth } = document.body;
    return `width:${clientWidth - 222}px`;
  }
  get WidthStyle() {
    const { clientWidth } = document.body;
    return `width:${clientWidth - 24}px`;
  }
  get getCharacterColor() {
    return `var(--card-color-${this.item.character})`;
  }
}
