<template>
  <div class="bgwrap">
    <div class="content">
      <div class="flex-column">
        <div style="font-weight: 800; text-align: center">
          确认退出
        </div>
        <div style="font-size: 14px; margin-top: 15px">
          取消支付，订单将在6分钟后自动关闭，若订单内含有优惠减免或限购等特惠，将在订单关闭后返还特惠。
        </div>
       
      </div>
      <div class="bottom">
      <div class="out" @click="out">退出</div>
        <div class="sure" @click="confirm">继续购买</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      document.documentElement.style.overflowY = "scroll";
    },
    out() {
        this.$emit("out");
        document.documentElement.style.overflowY = "scroll";
    }
  },
};
</script>
<style lang="scss" scoped>
.bgwrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba($color: #000000, $alpha: 0.8);
  width: 100%;
  height: 100%;
  z-index: 9999;

  .content {
    background: white;
    position: relative;
    top: 50%;
    left: 50%;
    height: 200px;
    transform: translate(-50%, -50%);
    width: 330px;
    border-radius: 15px;
    .flex-column {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding:30px 30px 15px 30px;
    }
    .bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px;
      .sure {
        width: 130px;
        height: 38px;
        background: black;
        color: white;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        margin-left: 8px;
      }
      .out {
        width: 130px;
        height: 38px;
        background: white;
        color: black;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        border: 1px solid #DEE0E2;
      }
    }
  }
}
</style>
