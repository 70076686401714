


















import InteractionLib from "@/utils/interaction.lib";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TitleMedia extends Vue {
  @Prop({ default: "" })
  private readonly background!: string;
  @Prop()
  private readonly soldout!:boolean
  @Prop({ default: "" })
  private readonly title!: string;

  @Prop({ default: "" })
  private readonly desc!: string;
  toMySupply(){
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/mysupply`);
    }
    this.$router.push({name:'mysupply'})
  }
}
