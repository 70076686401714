<template>
  <div :style="styleSheet" class="Card">
    合成升级
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
    Component:{

    }
})
export default class CombineUp extends Vue {
    @Prop wideHigh
    styleSheet = {}
    created(){
        
    }

}
</script>

<style lang="scss" scoped>
.Card{
    background-image: url('~@/assets/bgtoup.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
</style>