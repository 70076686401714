








import { getCharacter } from "@/utils/common.util";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class characterTag extends Vue {
  @Prop()
  item!: any;
  @Prop()
  index!: number;
  @Prop()
  SideCurrentIndex!: number;
  @Watch("SideCurrentIndex")
  getSideCurrentIndex() {}
  @Watch("index")
  getIndex() {}
  get BgColor() {
    if(this.SideCurrentIndex != this.item.level) return
    return `background: ${
      this.item.level >= 5 ? `#672932` : `rgba(${this.item.color})`
    }`;
  }
  created(){
  }
  @Emit()
  filterData(level:number){
    return level
  }
}
