
















import { Component, Prop, Vue } from "vue-property-decorator";
import { getCharacter, getCharacterColor } from "@/utils/common.util";

@Component({})
export default class BarrageItem extends Vue {
  @Prop({ default: "" })
  private readonly profile!: string;

  @Prop({ default: "" })
  private readonly nickname!: string;

  @Prop({ default: -1 })
  private readonly character!: number;

  @Prop({ default: "" })
  private readonly desc!: string;

  get characterText() {
    const text = getCharacter(this.character, "", true);
    const icon =
      this.character < 5 ? "" : `__character_peak_${this.character - 4}_icon`;
    return {
      text,
      icon,
    };
  }

  get characterStyle() {
    return getCharacterColor(this.character);
  }
}
