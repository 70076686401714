















import { Component, Prop, Vue } from "vue-property-decorator";
import BarrageItem from "./BarrageItem.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vueDanmaku from "vue-danmaku";
import { ISupplyShufflingMessage } from "@/api/supply-package.api";

@Component({
  components: {
    BarrageItem,
    vueDanmaku,
  },
})
export default class Barrage extends Vue {
  @Prop({ default: 2 })
  private readonly line!: number;

  @Prop({ default: () => [] })
  private readonly items!: Array<ISupplyShufflingMessage>;
}
