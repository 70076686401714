var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('destiny-frame',{attrs:{"id":"destiny-feed"}},[_c('template',{slot:"title"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',[_vm._v("概率公示")]),_c('span',{staticStyle:{"font-size":"12px","color":"#878E99","margin-left":"10px"}},[_vm._v("(概率已做小数点后三位四舍五入)")])])]),[_c('div',{staticClass:"__flex_"},_vm._l((_vm.getGroupLevelNum),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.previewPop(item.level)}}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","margin-right":"12px"}},[_c('div',{staticClass:"destiny-box",style:({
            background: ("" + (item.level >= 5 ? ("" + (item.color)) : ("rgb(" + (item.color) + ")"))),
          })},[_c('div',{staticClass:"destiny-box-content"},[_c('div',{staticClass:"destiny-box-bgopc",style:({
                background: ("" + (item.level >= 5
                    ? 'rgba(254, 47, 117, 0.05)'
                    : ("rgba(" + (item.color) + ",0.05)"))),
              })},[_c('div',{staticClass:"destiny-box-content-in",style:({
                  background: ("" + (item.level >= 5 ? "#672932" : ("rgba(" + (item.color) + ",0.3)"))),
                })},[_c('div',{staticClass:"destiny-box-content-wrap"},[_c('div',{staticClass:"destiny-box-left",style:({
                      background: ("" + (item.level >= 5
                          ? "#672932"
                          : ("rgba(" + (item.color) + ",0.3)"))),
                    })}),_c('div',{staticClass:"destiny-box-right",style:({
                      background: ("" + (item.level >= 5
                          ? "#672932"
                          : ("rgba(" + (item.color) + ",0.3)"))),
                    })}),_c('div',{staticClass:"destiny-box-opc"},[_c('div',{staticClass:"destiny-box-nums",style:({
                        fontWeight: '800',
                        color: ("" + (item.level >= 5 ? '#FF1F6A' : ("rgb(" + (item.color) + ")"))),
                      })},[_vm._v(" "+_vm._s(item.len)+"款 ")]),_c('div',{staticClass:"destiny-box-title",style:({
                        color: ("" + (item.level >= 5 ? '#FF1F6A' : ("rgb(" + (item.color) + ")"))),
                      })},[_vm._v(" "+_vm._s(item.title)+" ")])])])])])])]),(_vm.chanceRes)?_c('div',{staticClass:"rate-feed",style:({background: ("" + (item.level >= 5? "#672932": ("rgba(" + (item.color) + ",0.3)")))})},[_c('span',[_vm._v(_vm._s((_vm.ChanceRate[index]).toFixed(3))+"%")]),_c('img',{staticStyle:{"width":"8px","height":"8px"},attrs:{"src":"https://vgn-images-new.oss-cn-beijing.aliyuncs.com/static/newblind/icon%EF%BC%8Fdark%EF%BC%8Farrow_right%402x.png","alt":""}})]):_vm._e()])])}),0)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }