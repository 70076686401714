










import { Component, Vue } from "vue-property-decorator";
import SyntheticDialog from "@/components/SyntheticDialog.vue";

@Component({
  components: { SyntheticDialog },
})
export default class SynUpgradeAd extends Vue {
  dialog = false;

  handleAdRouter(): void {
    this.dialog = true;
  }
}
