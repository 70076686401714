














































































import { cloneDeep } from "lodash";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import TitleMedia from "./components/TitleMedia.vue";
import Barrage from "./components/barrage/Barrage.vue";
import DestinyFate from "./components/destinyFate/DestinyFate.vue";
import DestinyFeed from "./components/destinyFeed/DestinyFeed.vue";
import DestinyContent from "./components/destinyContent/DestinyContent.vue";
import DestinyFrame from "./components/DestinyFrame.vue";
import RatePop from "./components/RatePop/RatePop.vue";
import SynUpgradeAd from "./components/SynUpgradeAd.vue";
import PowerBuff from "./components/powerBuff/PowerBuff.vue";
import { getModule } from "vuex-module-decorators";
import { countdown, getNow, observer } from "@/utils/common.util";
import DestinyReward from "./components/destinyReward/DestinyReward.vue";
import SupplyPackageStateStore from "@/store/modules/supply-package-state.store";
import { Coupon, MineApi } from "@/api/mine.api";
import CombineUp from "@/views/destiny-draw/components/CombineUp.vue";

import SupplyPackageApi, {
  ISupplyBox,
  ISupplyDetail,
  ISupplyShufflingMessage,
} from "@/api/supply-package.api";
import { SHARE_TEXT } from "@/constant";
import InteractionLib from "@/utils/interaction.lib";
import MuchBlind from "./components/MuchBlind.vue";
import GlobalStateStore from "@/store/modules/global-state.store";
@Component({
  components: {
    DestinyReward,
    PowerBuff,
    SynUpgradeAd,
    DestinyFrame,
    DestinyFeed,
    DestinyFate,
    Barrage,
    RatePop,
    TitleMedia,
    DestinyContent,
    CombineUp,
    MuchBlind,
  },
})
export default class DestinyDrawIndex extends Vue {
  @Ref("destiny-reward-home")
  private readonly destinyRewardRef: DestinyReward | undefined;
  showRate: boolean  = false;
  showFlag: boolean = false;
  currentIndex: any;
  get flutter() {
    return InteractionLib.isApp();
  }
  DataList: any[] = []
  previewPop(res:any){
    document.documentElement.style.overflow = 'hidden'
    this.DataList  = res.DataList
    this.currentIndex = res.item
    this.showRate = true
    this.showFlag = true
  }
  closePop(){
    this.showRate = false
    this.showFlag = false

    document.documentElement.style.overflow = ''
  }
  box_id = Number(this.$route.params.id);
  supplyPackageState = getModule(SupplyPackageStateStore);
  supplyBox: Array<ISupplyBox> = [];
  detail: ISupplyDetail | null = null;
  shufflingMessage: Array<ISupplyShufflingMessage> = [];
  coupon: Coupon | null = null;
  desc = "";
  payBtnDisabled = false;
  updateDetail(option: Partial<ISupplyDetail>): void {
    if (!this.detail) return;
    const detail = cloneDeep(this.detail);
    this.detail = {
      ...detail,
      ...option,
    };
  }
  get onePrice(): number {
    const { price = 0, point = 0 } = this.detail || {};
    return this.payType ? price / 100 : point;
  }
  
  get payType(): boolean {
    const { pay_type = 0 } = this.detail || {};
    return Boolean(pay_type);
  }

  get tenPrice(): number {
    const { totle_price = 0, totle_point = 0 } = this.detail || {};
    return this.payType ? totle_price / 100 : totle_point;
  }

  handlePreviewCard(id: number): void {
    document.documentElement.style.overflowY = "hidden";
    this.destinyRewardRef?.playAnimation({
      items: this.supplyBox.filter((row) => row.id === id),
      option: false,
      test: false,
      preview: true,
      hiddenTitle: true,
      ensure: this.ensure,
    });
  }
  flag = false;
  handleDesc(): void {
    const descText = () => {
      const {
        end_time = 0,
        box_status = 1,
        start_time = 0,
        is_today_free = 0,
      } = this.detail || {};
      const now = getNow();
      if (box_status === 1 || end_time < now) return "已结束";
      if (is_today_free)
        // TODO: 临时变更
        return this.box_id == 175
          ? "当前补给箱新人仅限领取一次"
          : "当前补给箱可享每日首次免费";
      if (start_time > now) {
        const { day, hour, minute, second } = countdown(start_time);
        return `距开始 ${day}天${hour}时${minute}分${second}秒`;
      }
      const { day, hour, minute, second } = countdown(end_time);
      if (Number(day) > 13) {
        return "";
      }
      return `有效期 ${day}天${hour}时${minute}分${second}秒`;
    };

    const desc = descText();
    this.payBtnDisabled = desc === "已结束";
    if (this.desc === desc) return;
    this.desc = desc;
  }

  get fateStyle(): string {
    if (this.shufflingMessage.length) {
      return "margin-top: 42px;";
    }
    return "margin-top: -42px;";
  }

  get title(): string {
    const { title = "" } = this.detail || {};
    return title;
  }

  get cover(): string {
    const { cover = "" } = this.detail || {};
    return cover;
  }

  get content(): string {
    const { content = "" } = this.detail || {};
    return content
      .split("\n")
      .map((p) => `<p class="__desc __desc_f2 __desc_m">${p}</p>`)
      .join("\n");
  }

  get ensure(): { character: number; num: number; count: number } {
    const {
      ensure_num = 0,
      ensure_character = 0,
      current_count = 0,
    } = this.detail || {};
    return {
      num: ensure_num,
      count: current_count,
      character: ensure_character,
    };
  }

  get freeBox(): { is_free: boolean; is_has: boolean } {
    const { has_free_receive = false, is_today_free = false } =
      this.detail || {};
    return {
      is_free: Boolean(is_today_free),
      is_has: Boolean(has_free_receive),
    };
  }

  async initData(): Promise<void> {
    const [detail, supplyBox, shufflingMessage] = await Promise.all([
      this.supplyPackageState.getSupplyDetail(this.box_id).catch((err) => {
        return undefined;
      }),
      this.supplyPackageState.getSupplyBoxList(this.box_id).catch((err) => {
        return undefined;
      }),
      this.supplyPackageState
        .getSupplyShufflingMessage(this.box_id)
        .catch((err) => {
          return undefined;
        }),
    ]);
    this.detail = detail as any;
    this.supplyBox = supplyBox as any;
    this.shufflingMessage = shufflingMessage as any;
    setTimeout(
      () =>
        wx.ready(() => {
          wx.updateAppMessageShareData({
            title: `${(detail as any).title}-超级蒸汽商城`, // 分享标题
            desc: SHARE_TEXT, // 分享描述
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: (detail as any).cover, // 分享图标
          });
          wx.updateTimelineShareData({
            title: `${(detail as any).title}-超级蒸汽商城`, // 分享标题
            desc: SHARE_TEXT, // 分享描述
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: (detail as any).cover, // 分享图标
          });
        }),
      500
    );
  }

  @Watch("detail")
  private async watchDetail(detail: ISupplyDetail | null) {
    if (!detail || !detail.pay_type) return;
    const result = await MineApi.getMyCoupons(1, detail.id).catch((err) => {
      return undefined;
    });
    if (!(result as any)?.length) return;
    this.coupon = (result as any)?.find(Boolean) || null;
  }

  private handleUpdateCoupon() {
    if (!this.coupon) return;
    const { failure_time } = this.coupon;
    if (failure_time <= getNow()) {
      this.updateCoupon();
    }
  }

  private updateCoupon() {
    this.coupon = null;
    this.watchDetail(this.detail);
  }

  countdownNotice(): void {
    this.handleDesc();
    this.handleUpdateCoupon();
  }
  globalState = getModule(GlobalStateStore);

  async mounted(): Promise<void> {
    observer("refreshInit", async () => {
      if (!this.globalState.userInfo) {
        await this.globalState.getUserInfo();
      }
    });
    observer("BlindDetailInit", async () => {
      (this.detail as any) = await this.supplyPackageState
        .getSupplyDetail(this.box_id)
        .catch((err) => {
          return undefined;
        });
    });
    observer("countdownNotice", () => this.countdownNotice());
    SupplyPackageApi.recordVisitLog(this.box_id);
    await this.initData();
    InteractionLib.setWebViewTitle("超值补给箱");
  }
  wideHigh = 0;
  styleSheet = {};
  async created() {
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) - 12;
    this.styleSheet = {
      width: this.wideHigh + "px",
      height: this.wideHigh / 5.25 + "px",
    };
  }
}
