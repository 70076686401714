
















































import { ISupplyBox } from "@/api/supply-package.api";
import { TYPE_TEXT } from "@/constant";
import { groupBy } from "lodash";
import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import RateCard from "@/views/destiny-draw/components/RatePop/components/RateCard.vue";
import CharacterTag from "@/views/destiny-draw/components/RatePop/components/CharacterTag.vue";
import DestinyReward from "@/views/destiny-draw/components/destinyReward/DestinyReward.vue";
@Component({
  components: {
    RateCard,
    CharacterTag,
    DestinyReward,
  },
})
export default class RatePop extends Vue {
  TagList = [];
  @Prop({ default: () => [] })
  private items!: Array<ISupplyBox>;
  @Ref("destiny-reward-home")
  private readonly destinyRewardRef: DestinyReward | undefined;
  @Prop() DataList!: any;
  product: any[] = [];
  ChanceRate: any[] = [];
  checkList: any[] = [];
  @Prop() currentIndex!: any;

  SideCurrentIndex: number = 0;
  get tagStyle() {
    return;
  }
  filterData(level: number) {
    this.SideCurrentIndex = level;
  }
  handlePreviewCard(id: number): void {
    this.destinyRewardRef?.playAnimation({
      items: this.items.filter((row) => row.id === id),
      option: false,
      test: false,
      preview: true,
      hiddenTitle: true,
    });
  }
  created() {
    this.SideCurrentIndex = this.currentIndex;
  }
  @Watch("DataList")
  getDataList() {}
  @Watch("currentIndex")
  getcurrentIndex() {}
  characterText(value: number) {
    return TYPE_TEXT[value] ?? "";
  }
  get ClientWidthStyle() {
    const { clientWidth } = document.body;
    return `width:${clientWidth - 222}px`;
  }
  @Emit()
  closePop() {
    return;
  }
  chanceRes = 0;
  RateList: any[] = [];
  get getGroupLevelNum() {
    this.product = this.items.filter((item) => {
      if (item.level != -1) {
        return item;
      }
    });
    const row: any = groupBy(
      this.product.map((row) => ({
        ...row,
        level: row.level > 5 ? 5 : row.level,
      })),
      "level"
    );

    const levels = Object.keys(row);

    levels.forEach((item, index) => {
      row[levels[index]].map((res: any) => {
        if (!!!this.ChanceRate[index]) {
          this.ChanceRate[index] = res.original_chance;
        } else {
          this.ChanceRate[index] = res.original_chance + this.ChanceRate[index];
        }
      });
    });
    let chanceRes = 0;
    this.items.map((res) => {
      chanceRes += res.original_chance;
    });
    this.RateList = this.ChanceRate;

    this.ChanceRate = this.ChanceRate.map((res) => {
      return (res * 100) / chanceRes;
    });

    this.chanceRes = chanceRes;
    this.ChanceRate = this.ChanceRate.reverse();
    return levels
      .map((level) => ({
        level: Number(level),
        len: row[level].length,
        title:
          Number(level) >= 5 ? "巅峰" : TYPE_TEXT[level as unknown as number],
        color:
          Number(level) >= 5
            ? "linear-gradient(270deg, #FF9112 0%, #FF1C6D 100%)"
            : `${`var(--card-color-rgb-${level})`}`,
      }))
      .sort((a, b) => b.level - a.level);
  }
}
