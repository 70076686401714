








































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import DestinyFrame from "../DestinyFrame.vue";
import DestinyCard from "../newDestinyCard/NewDestinyCard.vue";
import { maxBy, minBy } from "lodash";
import { getChance, getChanceCause } from "@/utils/common.util";
import { TYPE_TEXT } from "@/constant";
import { ISupplyBox } from "@/api/supply-package.api";

@Component({
  components: {
    DestinyFrame,
    DestinyCard,
  },
})
export default class DestinyFeed extends Vue {
  @Prop({ default: true }) private readonly show_chance!: boolean;

  cardWideHigh = 120;

  show_all = false;

  @Prop({ default: () => [] })
  private readonly items!: Array<ISupplyBox>;

  @Emit()
  previewCard(id: number): number {
    return id;
  }

  get itemNum(): number {
    return this.items.length;
  }

  get supplyBoxPriceInterval(): string {
    const price = (price: number) => Math.ceil(price) / 100;
    const min = minBy(this.items, (row) => row.price)?.price || 0;
    const max = maxBy(this.items, (row) => row.price)?.price || 0;
    return `¥${price(min)}～¥${price(max)}`;
  }

  get containsItems(): { level: number; percentage: string; icon: string }[] {
    const total = this.itemNum;
    const percentage = (level: number) => {
      const items = this.items.filter((row) => row.level === level).length;
      return Math.floor((items / total) * 10000) / 100 + "%";
    };
    const icon = (level: number) =>
      level < 5 ? "" : `__character_peak_${level - 4}_icon`;
    return ["", ...TYPE_TEXT]
      .map((str, i) => ({
        level: i - 1,
        percentage: percentage(i - 1),
        icon: icon(i - 1),
      }))
      .reverse()
      .filter((row) => row.percentage !== "0%");
  }

  get cardList(): {
    cover: string;
    character: number;
    rate: string;
    price: number;
    id: number;
    type:number;
    product_bazaar_price: number;
    title: string;
  }[] {
    return this.items
      .map((row) => {
        return {
          id: row.id,
          product_bazaar_price: row.product_bazaar_price,
          cover: row.cover,
          character: row.level,
          type: row.type,
          title: row.title,
          price: row.price / 100,
          rate: `爆率 ${(row.chance/100).toFixed(2)}%`,
        };
      })
      .sort((a, b) => b.character - a.character)
      .slice(0, this.show_all ? this.items.length : 6);
  }
  get totalChange(){
    let nums:number = 0
    this.items.map((item)=>{
      nums += item.chance
    })
    return nums
  }
  calculativeWidth(): number {
    const __feeds = document.querySelector("#__feeds");
    const width = __feeds?.clientWidth || __feeds?.scrollWidth || 375;
    return width / 3 - 8;
  }

  mounted(): void {
    this.cardWideHigh = this.calculativeWidth();
  }
}
