





































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import DestinyFrame from "../DestinyFrame.vue";
import DestinyCard from "../destinyCard/DestinyCard.vue";
import { maxBy, minBy } from "lodash";
import { getChance } from "@/utils/common.util";
import { TYPE_TEXT } from "@/constant";
import { ISupplyBox } from "@/api/supply-package.api";
import { groupBy } from "lodash";

@Component({
  components: {
    DestinyFrame,
    DestinyCard,
  },
})
export default class DestinyFeed extends Vue {
  cardWideHigh = 120;
  show_all = false;
  totalKinds = 0;
  product: any[] = [];
  ChanceRate:any[] = []
  @Prop({ default: () => [] })
  private items!: Array<ISupplyBox>;
  DataList:any[] = []
  @Emit()
  previewCard(id: number): number {
    return id;
  }@Emit()
  previewPop(item:any) {
    return {item,DataList:this.DataList}
  }

  get itemNum(): number {
    return this.items.length;
  }

  get supplyBoxPriceInterval(): string {
    const price = (price: number) => Math.ceil(price) / 100;
    const min = minBy(this.items, (row) => row.price)?.price || 0;
    const max = maxBy(this.items, (row) => row.price)?.price || 0;
    return `¥${price(min)}～¥${price(max)}`;
  }

  get containsItems(): { level: number; percentage: string; icon: string }[] {
    const total = this.itemNum;
    const percentage = (level: number) => {
      const items = this.items.filter((row) => row.level === level).length;
      return Math.floor((items / total) * 10000) / 100 + "%";
    };
    const icon = (level: number) =>
      level < 5 ? "" : `__character_peak_${level - 4}_icon`;
    return ["", ...TYPE_TEXT]
      .map((str, i) => ({
        level: i - 1,
        percentage: percentage(i - 1),
        icon: icon(i - 1),
      }))
      .reverse()
      .filter((row) => row.percentage !== "0%");
  }

  get cardList(): {
    cover: string;
    character: number;
    rate: string;
    price: number;
    id: number;
    title: string;
  }[] {
    return this.items
      .map((row) => {
        return {
          id: row.id,
          cover: row.cover,
          character: row.level,
          title: row.title,
          price: row.price / 100,
          rate: `爆率 ${getChance(row.chance)}%`,
        };
      })
      .sort((a, b) => b.character - a.character)
      .slice(0, this.show_all ? this.items.length : 6);
  }

  calculativeWidth(): number {
    const __feeds = document.querySelector("#__feeds");
    const width = __feeds?.clientWidth || __feeds?.scrollWidth || 375;
    return width / 3 - 8;
  }
chanceRes = 0
  get getGroupLevelNum() {
    this.product = this.items.filter((item) => {
      if (item.level != -1) {
        return item;
      }
    });

    const row:any = groupBy(
      this.product.map((row) => ({
        ...row,
        level: row.level > 5 ? 5 : row.level,
      })),
      "level"
    );
    this.DataList = row
    const levels = Object.keys(row);
 
    levels.forEach((item,index)=>{
       row[levels[index]].map((res:any)=>{
         if(!!!this.ChanceRate[index]){
          this.ChanceRate[index] = res.original_chance 
         }else{
          this.ChanceRate[index] = res.original_chance + this.ChanceRate[index]
         }
      })
    })
    let chanceRes = 0
    this.items.map(res=>{
      chanceRes += res.original_chance
    })

   
    this.ChanceRate = this.ChanceRate.map(res=>{
      return (res*100/chanceRes)
    })
    this.chanceRes = chanceRes
    this.ChanceRate = this.ChanceRate.reverse()

    return levels
      .map((level) => ({
        level: Number(level),
        len: row[level].length,
        title:
          Number(level) >= 5 ? "巅峰" : TYPE_TEXT[level as unknown as number],
        color:
          Number(level) >= 5
            ? "linear-gradient(270deg, #FF9112 0%, #FF1C6D 100%)"
            : `${`var(--card-color-rgb-${level})`}`,
      }))
      .sort((a, b) => b.level - a.level);
  }

  mounted(): void {
    this.cardWideHigh = this.calculativeWidth();
  }
  created(): void {
    this.items.forEach((item, index) => {
      if (item.level != -1) {
        this.totalKinds = this.totalKinds + 1;
      }
    });
  }
}
